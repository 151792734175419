@import "./_variables.sass"

*
  box-sizing: border-box
  &:focus
    outline: none

html
  margin: 0
  padding: 0

body
  margin: 0
  padding: 0
  background: $darkgrey
  color: $text-color
  font-size: $font-regular
  line-height: 1.25
  font-family: $font-family
  &.mode--dark
    background: $darkmodeBackgroundColor
    color: $darkmodeTextRegular

a
  color: $text-color
  @at-root body.mode--dark &
    color: $darkmodeTextMedium

h1
  font-weight: bold
  font-size: $font-xl
  margin: 0
  @at-root body.mode--dark &
    color: $darkmodeTextLight

h2
  font-weight: bold
  font-size: $font-sm
  margin: 0
  @at-root body.mode--dark &
    color: $darkmodeTextLight

// Main app wrapper style
#app
  background-color: $background-color
  height: 100vh
  position: relative
  overflow: auto
  -ms-overflow-style: none
  scrollbar-width: none
  &.withmenu
    padding-bottom: calc(env(safe-area-inset-bottom) + 66px)
  &::-webkit-scrollbar
    display: none
  @at-root body.mode--dark &
    background-color: $darkmodeBackgroundColor

//Header
@keyframes gradient
  0%
    background-position: 0 0
  100%
    background-position: -200% 0
header
  background-color: $theme-background
  padding: 20px 16px
  position: relative
  // The loader on the header that activates when an HTTP request is made
  &::before
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 3px
    background: repeating-linear-gradient(to right, $theme-main 0%, $theme-background 50%, $theme-main 100%)
    background-size: 200% auto
    background-position: 0 100%
    animation: gradient 2s infinite
    animation-fill-mode: forwards
    animation-timing-function: linear
    opacity: 0
    transition: 0.2s ease-in-out
  &.loading:before
    opacity: 1

// Form styling
label
  font-size: 10px
  display: block
  font-weight: normal
  color: $darkgrey
  width: 100%
  margin: 0 0 3px 0
  @at-root body.mode--dark &
    color: $darkmodeTextRegular

input,select,textarea
  font-family: inherit
  display: block
  width: 100%
  border: 0
  border-radius: 0
  background-color: transparent
  border-bottom: 1px solid $lightgrey
  font-size: 16px
  line-height: 26px
  min-height: 31px
  color: $text-color
  line-height: 20px
  padding: 5px 0
  @at-root body.mode--dark &
    color: $darkmodeTextMedium
    border-color: $darkmodeBorderColor
  &:-webkit-autofill
    -webkit-text-fill-color: $text-color
    -webkit-box-shadow: 0 0 0 30px white inset !important
    @at-root body.mode--dark &
      -webkit-box-shadow: 0 0 0 30px $theme-background inset !important
      -webkit-text-fill-color: $darkmodeTextLight!important
  &:-webkit-autofill:hover
    -webkit-text-fill-color: $text-color
    -webkit-box-shadow: 0 0 0 30px white inset !important
    @at-root body.mode--dark &
      -webkit-box-shadow: 0 0 0 30px $theme-background inset !important
      -webkit-text-fill-color: $darkmodeTextLight!important
  &:-webkit-autofill:focus
    -webkit-text-fill-color: $text-color
    -webkit-box-shadow: 0 0 0 30px white inset !important
    @at-root body.mode--dark &
      -webkit-box-shadow: 0 0 0 30px $theme-background inset !important
      -webkit-text-fill-color: $darkmodeTextLight!important
  &:-webkit-autofill:active
    -webkit-text-fill-color: $text-color
    -webkit-box-shadow: 0 0 0 30px white inset !important
    @at-root body.mode--dark &
      -webkit-box-shadow: 0 0 0 30px $theme-background inset !important
      -webkit-text-fill-color: $darkmodeTextLight!important
  &:disabled
    //color: $darkergrey
    border-bottom: 0
input[type="datetime-local"]
  text-align: left
select[multiple]
  height: 31px
input
  &:-webkit-datetime-edit
    text-align: left
textarea
  width: 100%
  max-width: 100%
  min-height: 70px
  max-height: 500px


.formerrors
  padding: 5px 0 10px 0

.formerror
  color: $darkergrey
  font-size: $font-sm
  @at-root body.mode--dark &
    color: $darkmodeTextRegular
  i
    font-size: 14px
    color: $general-red
    margin-right: 5px

button,.button
  appearance: none
  font-family: inherit
  text-decoration: none
  background-color: $theme-main
  color: $light
  border: 1px solid $theme-main
  border-radius: 7px
  font-size: $font-sm
  font-weight: 600
  line-height: 20px
  padding: 8px 18px
  cursor: pointer
  display: inline-block
  text-align: center
  &:disabled
    background-color: $darkgrey
  &.secondary
    background-color: transparent
    border: 1px solid $theme-main
    color: $theme-main
  &.green
    background-color: $general-green
    border: 1px solid $general-green
  &.red
    background-color: $general-red
    border: 1px solid $general-red
  &.small-secundary
    background-color: transparent
    padding: 3px 3px
    border-radius: 0
    color: $darkergrey
    border: 0
  &.show_more_small
    padding: 0 6px
    margin: 2px 0 0 0
    background-color: $theme-background
    font-size: $font-xxs
    font-weight: 500
    line-height: 18px
    color: $text-color
    border-radius: 3px
    border: 0
    @at-root body.mode--dark &
      color: $darkmodeTextLight

.question
  margin: 0 0 15px 0

.questionwrap
  display: flex
  justify-content: space-between
  .question
    width: calc(50% - 7.5px)
    &.fullwidth
      width: 100%
    .val
      font-size: $font-sm
      line-height: 31px
// Default page/widet styling
.page
  padding:20px 16px

.widget
  padding: 20px 16px
  &.noPaddingRight
    padding-right: 0
    .no-results
      margin-right: 16px
  h2
    margin-bottom: 10px

// Default list styling
.list
  margin: 0 -16px
.list-item
  display: flex
  border-bottom: 1px solid $lightgrey
  padding: 10px 16px
  text-decoration: none
  background-color: transparent
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  @at-root body.mode--dark &
    border-bottom-color: $darkmodeBorderColor
  &:last-child
    border-bottom: 0
  &.batch
    background-color: $theme-background
  .list-item-content
    flex-shrink: 1
    width: calc(100% - 53px)
    display: flex
    .list-item-title
      font-size: $font-regular
      &.title_mail
        @at-root body.mode--dark &
          color: $darkmodeTextRegular
      &.unread
        font-weight: 600
        @at-root body.mode--dark &
          color: $darkmodeTextLight
      .fa-exclamation
        color: #F15F79
        font-size: 10px
        margin-right: 5px
    .list-item-date
      font-size: 10px
    .list-item-status
      font-size: 10px
    .fa-paperclip
      color: $darkergrey
      font-size: 10px
    .icon-reverse
      transform: rotateY(180deg)
    i.fa-solid.fa-share
      font-size: 10px
      color: #767898
  .list-item-content-left
    flex-shrink: 1
    flex-grow: 1
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    max-width: 100%
    font-size: $font-sm
    display: flex
    flex-wrap: wrap
    align-items: center
    .list-item-title
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 100%
      width: 100%
      &.medium
        font-weight: 500
      &.semibold
        font-weight: 600
    .list-item-subject
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 100%
      width: 100%
      @at-root body.mode--dark &
        color: $darkmodeTextLight
      &.subject_mail
        @at-root body.mode--dark &
          color: $darkmodeTextRegular
      &.unread
        @at-root body.mode--dark &
          color: $darkmodeTextLight
      &.medium
        font-weight: 500
      &.semibold
        font-weight: 600
    .list-item-subtitle
      color: $darkergrey
      @at-root body.mode--dark &
        color: $darkmodeTextRegular
    .list-item-description
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 100%
      width: 100%
      color: $darkergrey
      @at-root body.mode--dark &
        color: $darkmodeTextRegular
      &:empty
        display: none
  .list-item-content-right
    flex-shrink: 0
    flex-grow: 0
    .top
      display: flex
      justify-content: flex-end
      padding-bottom: 5px
      i
        margin-right: 5px
    .bottom
      display: flex
      justify-content: flex-end
      .folder-name
        font-size: $font-xxs
        line-height: 20px
        background-color: $theme-background
        color: $darkergrey
        border-radius: 3px
        padding: 1px 5px
        @at-root body.mode--dark &
          color: $darkmodeTextRegular
      .mail-icon
        @include mail-icon
  &.batch
    .list-image
      transform: rotateY(180deg)
      .entity-tag
        opacity: 0
      &::before
        opacity: 1
  .list-image
    @include mail-image
    transition: 0.3s linear
    &::before
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12.856' viewBox='0 0 18 12.856'%3E%3Cpath id='Path_440' data-name='Path 440' d='M17.623,96.387a1.283,1.283,0,0,1,0,1.816L7.337,108.49a1.283,1.283,0,0,1-1.816,0L.377,103.347A1.285,1.285,0,0,1,2.2,101.53l4.2,4.231,9.414-9.374a1.281,1.281,0,0,1,1.816,0Z' transform='translate(0 -96.01)' fill='%23fff'/%3E%3C/svg%3E%0A")
      transform: rotateY(180deg)
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: $theme-main
      border-radius: 100%
      opacity: 0
      line-height: 40px
      transition-delay: 0.15s

// FullCalendar styling -- TO BE MOVED!
.fullcalendar-wrap
  .fc-theme-standard
    td,th,.fc-scrollgrid
      border: 0
  .fc-col-header-cell-cushion
    text-transform: capitalize
    border-bottom: 1px solid $lightgrey
  .fc-daygrid-event
    .fc-event-time
      font-weight: bold
    .fc-event-title
      font-weight: normal
  td.fc-daygrid-day.fc-day.fc-day-today
    .fc-daygrid-day-number
      background-color: $theme-main
      color: #fff
      border-radius: 100%
      width: 23px
      height: 23px
      line-height: 23px
      padding: 0
      text-align: center
.fullcalendar-wrap
  .fc
    .fc-timegrid-divider
      border: 0
      padding: 0

    .fc-daygrid-body-natural
      .fc-daygrid-day-events
        margin: 0
    td.fc-daygrid-day.fc-day
      background-color: #fff
      border-width: 0
      font-size: $font-sm
      a.fc-daygrid-event.fc-event
        border-width: 0px 0px 0px 5px
        padding-left: 2px
        font-size: 9px
      .fc-daygrid-more-link
        font-size: 8px
        pointer-events: none
    .fc-daygrid-week-number
      font-size: 9px
      background-color: transparent

.fullcalendar-wrap .fc td.fc-daygrid-day.fc-day
  @at-root body.mode--dark &
    background-color: $darkmodeBackgroundColor
.fullcalendar-wrap .fc-col-header-cell-cushion
  @at-root body.mode--dark &
    border-bottom: 0
    color: $darkmodeTextMedium!important

.fc-daygrid-event-dot
  display: none


.no-results
  padding: 20px 16px
  text-align: center
  background: $theme-background
  border-radius: 5px
  font-size: 12px
  font-weight: 500


// Slider styling -- To be moved
.swiper-slide
  transform-origin: center
  transform: scale(1)!important
  opacity: 1
  transition: 0.2s ease-in-out
.swiper-slide:not(.swiper-slide-active)
  opacity: 0.7
  transform-origin: center
  transform: scale(0.9)!important
  transition: 0.6s ease-in-out



.cupertino-pane-wrapper
  .move
    background-color: #F3F5FF
    height: 3px
    margin-top: 5px
  .destroy-button
    display: none


.swiper-pagination
  padding: 15px 0 0 0
  display: flex
  .swiper-pagination-bullet
    display: block
    font-size: $font-xxs
    margin-right: 20px
    font-weight: 600
    padding-bottom: 6px
    color: $darkergrey
    text-transform: capitalize
    @at-root body.mode--dark &
      color: $darkmodeTextRegular
    &:last-child
      margin-right: 0
    &.swiper-pagination-bullet-active
      color: $theme-main
      border-bottom: 2px solid $theme-main
      @at-root body.mode--dark &
        color: $theme-main
.dossier-tab-content button.appActionButtonSingle
  position: absolute
  bottom: 30px

// Tab styling
.tab
  height: calc(100vh - 132px - 66px - env(safe-area-inset-bottom))
  .tab-inner
    overflow: auto
    height: 100%
    padding: 25px 16px
    .icon-and-text-vals
      margin: 0 0 20px 0
    h3
      font-size: 14px
      margin: 0 0 15px 0
  .relation-vacancies-list,.vacancy-procedures-list,.relation-contacts-list
    margin: -10px -16px 0 -16px

.critval
  margin: 0 0 10px 0
  padding: 5px 0 0 0
  .value span
    display: inline-block
    padding: 2px 10px
    font-size: $font-sm
    background-color: $theme-background
    border-radius: 5px
    margin-right: 5px
    margin-bottom: 5px
    @at-root body.mode--dark &
      color: $darkmodeTextLight

.job-applycount
  font-size: $font-sm
  i
    color: $theme-main
    margin-right: 5px


.ck-editor__editable_inline
  min-height: 120px

app-favorite-star
  margin-left: auto

.mail_filters_wrap
  display: flex
  justify-content: space-between
  align-items: flex-end


.setting
  .swiper-slide
    .theme-option
      transform: scale(0.7)
      width: 20vw
      height: 20vw
      border-radius: 100%
      transition: 0.2s ease-in-out
      .inner
        width: 100%
        height: 100%
        border-radius: 100%
    &.swiper-slide-active
      .theme-option
        transform: scale(1)
        border: 6px solid
        @at-root body.mode--dark &
          border: 6px solid $theme-background!important

.cupertino-pane-wrapper .pane
  @at-root body.mode--dark &
    background-color: $darkmodeBackgroundColor2
    color: $darkmodeTextMedium

.ck.ck-toolbar
  @at-root body.mode--dark &
    background-color: $theme-background
    border-color: $darkmodeBorderColor
.ck.ck-icon, .ck.ck-icon *
  @at-root body.mode--dark &
    color: $darkmodeTextMedium
.ck.ck-editor__main>.ck-editor__editable
  @at-root body.mode--dark &
    background-color: $darkmodeBackgroundColor2
    color: $darkmodeTextMedium
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)
  @at-root body.mode--dark &
    border-color: $darkmodeBorderColor
.ck.ck-button.ck-on:not(.ck-disabled), a.ck.ck-button.ck-on:not(.ck-disabled)
  &:hover
    @at-root body.mode--dark &
      background-color: $darkmodeBackgroundColor2
.ck.ck-button.ck-on
  @at-root body.mode--dark &
    background-color: $darkmodeBackgroundColor2
.ck.ck-button
  &:hover
    @at-root body.mode--dark &
      background-color: transparent
.ck.ck-content.ck-editor__editable
  font-size: 16px
.cupertino-pane-wrapper .move
  @at-root body.mode--dark &
    background-color: $darkmodeBorderColor
