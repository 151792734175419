// Default app styles
$dark: #00011D
$light: #fff

$background-color: $light
$text-color: $dark

$font-family: 'Inter', sans-serif
$font-regular: 14px
$font-xl: 16px
$font-sm: 12px
$font-xs: 11px
$font-xxs: 10px

$font-weight-semibold: 600

$darkmodeBackgroundColor : #161B22
$darkmodeBackgroundColor2: #222932
$darkmodeTextRegular : #A2ABB7
$darkmodeTextMedium: #D9D9D9
$darkmodeTextLight: #F2F2F2
$darkmodeBorderColor: #303945
$darkmodeInputTextColor: #7B8694
$darkmodeIconcolor: #69778A

// Default
$general-red: #F15F79
$general-green: #06BEB6

// Styles based upon theme
$theme-main: var(--theme-main)
$theme-background: var(--theme-background)

$lightgrey: #E1E1E8
$darkgrey:  #9193AD
$darkergrey: #767898

@mixin mail-image
  width: 38px
  height: 38px
  border-radius: 100%
  margin-right: 15px
  background-color: $theme-background
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  position: relative
  font-size: $font-regular
  line-height: 38px
  color: $theme-main
  font-weight: bold
  text-transform: uppercase
  text-align: center
  flex-shrink: 0
  position: relative
  .entity-tag
    position: absolute
    background-color: #B923B4
    color: $light
    font-family: "FontAwesome"
    font-size: 6px
    line-height: $font-sm
    width: 13px
    height: 13px
    display: block
    border-radius: 100%
    top: 26px
    left: 26px
    opacity: 1
    transition-delay: 0.15s
    &.candidate-tag
      background-color: $theme-main
    &.relationcontact-tag
      background-color: #06BEB6
    &+ .entity-tag
      display: none

@mixin mail-icon
  width: 22px
  height: 22px
  background-color: $theme-background
  display: flex
  align-items: center
  justify-content: center
  color: #B5B5CC
  margin-left: 5px
  border-radius: 3px
  &:first-child
    margin-left:0
  i
    color: #B5B5CC
    font-size: 10px

@mixin flag-styling
  .fa-flag
    color: #B5B5CC
    &.tag_1
      color: #F15F79
    &.tag_2
      color: #FCAA3F
    &.tag_3
      color: #202020
    &.tag_4
      color: #06BEB6
    &.tag_5
      color: #40ADFA
    &.tag_6
      color: #B923B4
